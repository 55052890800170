import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

export default function CallToActionLabel({
  children,
  variant,
  size,
  className,
}) {
  const variants = {
    light:
      "text-black bg-white border border-transparent hover:text-pink focus:border-pink-300 focus:ring-pink-300 focus:text-black",
    "light-outline":
      "text-white bg-transparent border border-white hover:text-mint hover:border-mint focus:border-mint-300 focus:ring-mint-300 focus:text-white",
    dark:
      "text-white bg-gray-800 border border-transparent hover:bg-purple focus:ring-purple-300 focus:border-purple-300 focus:bg-purple",
    "dark-outline":
      "text-gray-900 bg-transparent border border-gray-900 hover:border-purple focus:ring-purple-300 focus:border-purple-300",
    vivid:
      "text-white bg-purple border border-transparent hover:bg-purple-400 focus:bg-purple focus:border-purple-300 focus:ring-purple-300 focus:ring shadow-[0_4px_0_0_rgba(73,22,169,1),0_8px_0_0_rgba(0,0,0,0.25)]",
    link:
      "text-black underline border border-transparent hover:text-pink focus:border-pink-300 focus:ring-pink-300 focus:text-black",
  };

  const sizes = {
    sm: "text-xs h-button-sm px-sm",
    md: "text-sm h-button-md px-sm",
    lg: "text-md font-bold h-button-lg px-sm",
    xl: "text-md font-bold h-button-xl px-md",
  };

  return (
    <div
      className={twMerge(
        "inline-flex items-center justify-center select-none rounded transition-colors focus:outline-none focus:ring",
        variants[variant],
        sizes[size],
        className,
      )}
    >
      {children}
    </div>
  );
}

CallToActionLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  variant: PropTypes.oneOf([
    "dark",
    "dark-outline",
    "light",
    "light-outline",
    "link",
    "vivid",
  ]),
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  className: PropTypes.string,
};
