import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { motion } from "framer-motion";

const missingProvider = "Component must be used within <RadioGroup.Root>";

export const RadioGroupContext = React.createContext({
  get selectedValue() {
    throw new Error(missingProvider);
  },
  get headless() {
    throw new Error(missingProvider);
  },
});

RadioGroupContext.displayName = "RadioGroupContext";

export function Root({
  defaultValue,
  value,
  onValueChange,
  disabled,
  name,
  required,
  orientation,
  headless,
  className,
  children,
}) {
  const [selectedValue, setSelectedValue] = React.useState(
    value || defaultValue,
  );

  React.useEffect(() => {
    if (value !== selectedValue) {
      setSelectedValue(value);
    }
  }, [value, selectedValue]);

  const providerValue = React.useMemo(() => ({ headless, selectedValue }), [
    headless,
    selectedValue,
  ]);

  return (
    <RadioGroupContext.Provider value={providerValue}>
      <RadioGroupPrimitive.Root
        defaultValue={defaultValue}
        value={selectedValue}
        onValueChange={(newValue) => {
          setSelectedValue(newValue);
          onValueChange(newValue);
        }}
        disabled={disabled}
        name={name}
        required={required}
        orientation={orientation}
        className={
          headless
            ? className
            : twMerge(
                "flex",
                orientation === "vertical"
                  ? "flex-col space-y-5"
                  : "flex-row space-x-5",
                className,
              )
        }
      >
        {children}
      </RadioGroupPrimitive.Root>
    </RadioGroupContext.Provider>
  );
}

export function Item({
  value,
  disabled,
  required,
  label,
  description,
  className,
  children,
}) {
  const id = React.useId();
  const { headless, selectedValue } = React.useContext(RadioGroupContext);
  const checked = selectedValue === value;

  if (headless) {
    return (
      <RadioGroupPrimitive.Item
        id={id}
        value={value}
        disabled={disabled}
        required={required}
        className={className}
      >
        {children}
      </RadioGroupPrimitive.Item>
    );
  }

  return (
    <div className={twMerge("flex flex-row w-fit", className)}>
      <RadioGroupPrimitive.Item
        id={id}
        value={value}
        disabled={disabled}
        required={required}
        asChild={true}
      >
        <motion.button
          className="w-4 h-4 rounded-full border-sm outline-none cursor-default flex-shrink-0"
          initial={false}
          animate={checked ? "checked" : "unchecked"}
          variants={{
            unchecked: {
              borderColor: "var(--color-gray-300)",
              backgroundColor: "var(--color-white)",
            },
            checked: {
              borderColor: "var(--color-purple)",
              backgroundColor: "var(--color-purple)",
              scale: 1.1,
            },
          }}
          whileFocus={{
            outlineColor: `var(--color-purple)`,
          }}
        >
          <RadioGroupPrimitive.Indicator className="flex items-center justify-center w-full h-full rounded-full">
            <span className="w-2 h-2 rounded-full bg-white" />
          </RadioGroupPrimitive.Indicator>
        </motion.button>
      </RadioGroupPrimitive.Item>

      <label htmlFor={id} className="flex flex-col ml-3">
        <span className="text-sm text-gray-700 leading-none">{label}</span>

        <span className="text-sm text-gray-500">{description}</span>
      </label>
    </div>
  );
}

Root.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  headless: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.node,
};

Root.defaultProps = {
  defaultValue: undefined,
  value: undefined,
  disabled: false,
  name: undefined,
  required: false,
  orientation: "vertical",
  headless: false,
  className: undefined,
  children: null,
  onValueChange: () => {},
};

Item.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.node,
};

Item.defaultProps = {
  value: undefined,
  disabled: false,
  required: false,
  label: undefined,
  description: undefined,
  className: undefined,
  children: null,
};
