import React from "react";
import PropTypes from "prop-types";
import Script from "next/script";
import { useRouter } from "next/router";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import BugsnagClient from "../../utils/bugsnag";
import { useUser } from "../user";
import { USER_TYPES, QUERY_KEYS, ROUTE_PREFIX } from "../../constants";
import api from "../../api";

const fetcher = api();

export function PianoProvider({ children }) {
  const { user } = useUser();
  const router = useRouter();
  const queryClient = useQueryClient();
  const [isExperienceInitialized, setIsExperienceInitialized] = React.useState(
    false,
  );

  const { mutate: redeemContract } = useMutation(
    (contractId) => fetcher.redeemContract(contractId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QUERY_KEYS.USER,
          QUERY_KEYS.USER_ACCESSES,
        ]);
      },
    },
  );

  React.useEffect(() => {
    // Allows buffering of commands before Piano script loads
    window.tp = window.tp || [];
  }, []);

  React.useEffect(() => {
    window.tp.push([
      "setComposerHost",
      process.env.NEXT_PUBLIC_PIANO_COMPOSER_HOST,
    ]);
    window.tp.push(["setPianoIdUrl", process.env.NEXT_PUBLIC_PIANO_ID_URL]);
    window.tp.push(["setEndpoint", process.env.NEXT_PUBLIC_PIANO_ENDPOINT]);
    window.tp.push([
      "setStaticDomain",
      process.env.NEXT_PUBLIC_PIANO_CDN_DOMAIN,
    ]);
  }, []);

  React.useEffect(() => {
    if (!user.pianoAuthToken) {
      window.tp.push(["setExternalJWT", undefined]);
    }
  }, [user.pianoAuthToken]);

  React.useEffect(() => {
    const currentUserType = Object.values(USER_TYPES).find(
      (userType) => userType.id === user.userTypeId,
    );

    if (user.features.pianoReceiveUserData) {
      window.tp.push(["setCustomVariable", "userType", currentUserType?.name]);
    }
  }, [user.userTypeId, user.features.pianoReceiveUserData]);

  /**
   * Initialize Piano experience and set external JWT token.
   * This effect ignores the user.pianoAuthToken and user.features.pianoReceiveUserData dependency, as syncing the token
   * runs on page navigation. This will cause tabs to include stale token data, but that is acceptable from the product team.
   * If issues arise, from the fact that server state is not in sync with client state, this effect should be updated.
   */
  React.useEffect(() => {
    if (user.features.pianoReceiveUserData) {
      window.tp.push(["setExternalJWT", user.pianoAuthToken]);
    }

    window.tp.push([
      "init",
      () => {
        window.tp.experience.init();
        setIsExperienceInitialized(true);
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const handleRouteChange = () => {
      if (isExperienceInitialized) {
        if (user.features.pianoReceiveUserData) {
          window.tp.push(["setExternalJWT", user.pianoAuthToken]);
        }
        window.tp.experience.execute();
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [
    router.events,
    isExperienceInitialized,
    user.pianoAuthToken,
    user.features.pianoReceiveUserData,
  ]);

  React.useEffect(() => {
    let script = undefined;

    if (
      user.features.piano &&
      user.isLoggedIn &&
      router.pathname === ROUTE_PREFIX.HOME &&
      router.query.contractId
    ) {
      script = document.createElement("script");
      script.src =
        "https://cdnjs.cloudflare.com/ajax/libs/angular.js/1.2.22/angular.min.js";
      script.async = true;

      script.onerror = () => {
        redeemContract(router.query.contractId);
      };

      document.body.appendChild(script);
    }

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [
    redeemContract,
    user.isLoggedIn,
    router.pathname,
    router.query.contractId,
    user.features.piano,
  ]);

  return (
    <>
      {user.features.piano ? (
        <Script
          src={`${process.env.NEXT_PUBLIC_PIANO_SCRIPT_URL}?aid=${process.env.NEXT_PUBLIC_PIANO_APPLICATION_ID}`}
          onError={(event) => {
            BugsnagClient.notify({
              name: "Piano script failed to load",
              message: `${event.target.src} failed to load`,
            });
          }}
          strategy="afterInteractive"
        />
      ) : null}

      {children}
    </>
  );
}

PianoProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
