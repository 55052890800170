import React from "react";
import PropTypes from "prop-types";
import * as PrimitiveToast from "@radix-ui/react-toast";
import { motion, AnimatePresence } from "framer-motion";

const missingProvider = "Component must be used within <Alert.Provider>";

export const AlertContext = React.createContext({
  get publish() {
    throw new Error(missingProvider);
  },
});

export function Provider({ offset = 0, children }) {
  const [alert, setAlert] = React.useState();

  const publish = React.useCallback((message) => {
    setAlert(message);
  }, []);

  const value = React.useMemo(() => ({ publish }), [publish]);

  return (
    <PrimitiveToast.Provider duration={3000}>
      <AlertContext.Provider value={value}>
        {children}

        <AnimatePresence>
          {alert ? (
            <PrimitiveToast.Root
              asChild={true}
              forceMount={true}
              onOpenChange={(open) => {
                if (!open) setAlert(undefined);
              }}
            >
              <motion.li
                className="container flex justify-center"
                initial="closed"
                animate="enter"
                exit="closed"
                variants={{
                  closed: {
                    opacity: 0,
                    transition: {
                      duration: 0.2,
                      ease: "easeIn",
                    },
                  },
                  enter: {
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                      ease: "easeOut",
                    },
                  },
                }}
              >
                <div className="rounded bg-pink w-fit shadow-lg p-3">
                  <PrimitiveToast.Description className="text-md text-center font-bold text-white">
                    {alert}
                  </PrimitiveToast.Description>
                </div>
              </motion.li>
            </PrimitiveToast.Root>
          ) : null}
        </AnimatePresence>
      </AlertContext.Provider>

      <PrimitiveToast.Viewport
        className="w-full fixed z-50"
        style={{
          top: offset,
        }}
      />
    </PrimitiveToast.Provider>
  );
}

export function useAlert() {
  const { publish } = React.useContext(AlertContext);

  return {
    publish,
  };
}

Provider.propTypes = {
  offset: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
